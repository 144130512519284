
export const ADD_REMINDER = 'ADD_REMINDER'
export const REMOVE_REMINDER = 'REMOVE_REMINDER'
export const SAVE_REMINDER = 'SAVE_REMINDER'
export const CONFIRM_BREAK = 'CONFIRM_BREAK'

export function addReminder(r) {
  return {
    type : ADD_REMINDER,
    payload: r
  }
}

export function removeReminder(r) {
  return {
    type : REMOVE_REMINDER,
    id: r.id
  }
}

export function confirmBreak(id) {
  return {
    type: CONFIRM_BREAK,
    id
  }
}

export function saveReminder(r) {
  return {
    type: SAVE_REMINDER,
    payload: r
  }
}
