import {
  ADD_REMINDER,
  CONFIRM_BREAK,
  REMOVE_REMINDER,
  SAVE_REMINDER
} from '../actions/reminders'

var defaultReminders = [
  {
    id: "bcc93e85-d521-447a-b8e6-6369c9656b5d",
    type: "interval",
    minutes: "50",
    name: "Coffee Break",
    total: 0
  },
  {
    id: "92d1436a-949f-42c7-927d-2ecd4eee418f",
    type: "daily",
    hours: "13",
    minutes: "00",
    name: "Lunch Break",
    total: 0
  }
]


export default function reminders (state = defaultReminders, action) {
  switch (action.type) {
    case SAVE_REMINDER :
    return state.map((item, index) => {
      if(item.id === action.payload.id) {
        return action.payload
      }
      return item
    })
    case ADD_REMINDER :
    return [
      ...state,
      action.payload
    ]
    case CONFIRM_BREAK :
    return state.map((item, index) => {
      if(item.id === action.id) {
        return  {
          ...item,
           total: item.total + 1
          }
        }
      return item
    })
    case REMOVE_REMINDER :
    return state.filter(reminder => reminder.id !== action.id)
    default :
      return state
  }
}
