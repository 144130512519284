import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
 } from 'reactstrap'

class EditModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: props.isOpen,
      actionText: props.action,
      reminder: props.reminder,
      step: 1
    };
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  handlePreviousClick() {
    this.setState(prevState => ({
      step: prevState.step - 1
    }))
  }
  handleNextClick() {
    this.setState(prevState => ({
      step: prevState.step + 1
    }))
  }

  handleSaveClick() {
   const { onSave } = this.props
   onSave(this.state.reminder)
  }

  reminderStateChange(key, val) {
    this.setState(prevState => ({
    ...prevState,
    reminder: {
      ...prevState.reminder,
       [key]: val
      }
    }))
  }

  handleTypeChange(e) {
    const type = e.target.value
    // reset some values
    if (type ==='interval') {
      this.reminderStateChange('minutes', '50')
    } else {
      this.reminderStateChange('hours', '13')
      this.reminderStateChange('minutes', '00')
    }
    this.reminderStateChange('type', e.target.value)
  }

  timeStateChange(val) {
    const vals = val.split(':')
    this.reminderStateChange('hours', vals[0])
    this.reminderStateChange('minutes', vals[1])
  }

  render() {
    const { earlyExit } = this.props
    const { modal, step, actionText, reminder } = this.state

    return (
      <Modal isOpen={modal} toggle={this.toggle} onClosed={() => earlyExit()} backdrop={'static'}>
        <ModalHeader toggle={this.toggle}>{actionText} Reminder</ModalHeader>
        <ModalBody>
        {step === 1 && (
          <FormGroup tag="fieldset">
          <legend>First, what type of break is this?</legend>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="type"
                value="interval"
                min={10}
                checked={reminder.type === "interval"}
                onChange={(e) => this.handleTypeChange(e)}
              />{' '}Interval - A stretch every X number of minutes
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
            <Input
              type="radio"
              name="type"
              value="daily"
              checked={reminder.type === "daily"}
              onChange={(e) => this.handleTypeChange(e)}
            />{' '}Daily - A once a day reminder at a fixed time, lunch break!
            </Label>
          </FormGroup>
        </FormGroup>
        )}
        {step === 2 && (

          <>
          {reminder.type === 'interval' && (
          <FormGroup tag="fieldset">
          <legend>Great, now tell us how often you want to see the reminder</legend>
            <FormGroup>
            Every <Input
                type="number"
                name="number"
                id="exampleNumber"
                placeholder="number placeholder"
                value={reminder.minutes}
                onChange={(e) => this.reminderStateChange('minutes', e.target.value)}
              /> minutes
            </FormGroup>
          </FormGroup>
          )}

          {reminder.type !== 'interval' && (
          <FormGroup tag="fieldset">
          <legend>Great, now tell us what time you see the reminder</legend>
            <FormGroup>
                <Input
                  type="time"
                  name="time"
                  id="exampleTime"
                  placeholder="time placeholder"
                  value={reminder.hours + ':' + reminder.minutes}
                  onChange={(e) => this.timeStateChange(e.target.value)}
                />
            </FormGroup>
          </FormGroup>
          )}
          </>
        )}
        {step === 3 && (
          <FormGroup tag="fieldset">
          <legend>Finally, lets give your reminder a name</legend>
            <FormGroup>
                <Input
                  type="text"
                  name="remindername"
                  id="reminderName"
                  placeholder="Tea Break!"
                  value={reminder.name}
                  onChange={(e) => this.reminderStateChange('name', e.target.value)}
                />
            </FormGroup>
          </FormGroup>
        )}
        </ModalBody>
        <ModalFooter>
          {step > 1 && (
            <Button color="secondary" onClick={() => this.handlePreviousClick()}>Back</Button>
          )}
          {step < 3 && (
            <Button color="secondary" onClick={() => this.handleNextClick()}>Next</Button>
          )}
          {step === 3 && (
            <Button color="primary" onClick={() => this.handleSaveClick()}>Save</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditModal
