import Reminder from './Reminder'

class Interval extends Reminder {

  static getDerivedStateFromProps(props, state) {
    const interval = (props.reminder) ? props.reminder.minutes : 0
    if (props.reminder !== state.reminder) {
      return {
        reminder: props.reminder,
        type: 'interval',
        subtitle: 'A break every ' + interval + ' minutes',
        secondsLeft: (interval * 60)
      };
    }
    return null
  }

  handleTick() {
    let diff = this.state.secondsLeft - 1
    if (diff <= 0) {
      this.takeBreak()
      return
    }
    const timeLeft = this.getTimeRemaining(diff)
    const interval = this.props.reminder.minutes
    this.setState(prevState => ({
      ...prevState,
      remaining: timeLeft,
      secondsLeft: diff,
      percent: 100 - (this.state.secondsLeft / (interval * 60)) * 100
    }))
  }

 reset() {
   const interval = (this.props.reminder) ? this.props.reminder.minutes : 50
   this.setState({
     secondsLeft: interval * 60
   })
 }
}

export default Interval
