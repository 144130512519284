import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class BreakModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.isOpen,
      countdown: 10,
      openTime: 0,
      isHovering: false,
      sinBinStyles: sinBin
    };
    this.toggle = this.toggle.bind(this)
  }

  componentWillMount() {
    var self = this
    this.reset()
    this.interval = setInterval(() => {
      self.handleTick()
    }, 1000)
  }

  reset() {
    this.setState(prevState => ({
      ...prevState,
      countdown: 10,
      openTime: 0
    }))
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleTick() {
    const { isHovering, openTime, countdown } = this.state
    const { isOver } = this.props

    if (isHovering) {
      if (countdown > 0) {
        // countdown as the user is hovering
        this.setState(prevState => ({
          ...prevState,
          countdown: prevState.countdown - 1
        }))
      } else {
        // break finished with valid break taken
        isOver(true)
      }
    } else {
      if (openTime > 60) {
        // break is over but did not complete,
        // this is a timeout
        isOver(false)
      } else {
        this.setState(prevState => ({
          ...prevState,
          openTime: prevState.openTime + 1
        }))
      }
     }

  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onMouseEnterHandler() {
    this.setState(prevState => ({
      ...prevState,
      isHovering: true,
      sinBinStyles: Object.assign({},
       sinBin,
       sinBinAmber
       )
    }))

  }

  onMouseLeaveHandler() {
    this.setState(prevState => ({
      ...prevState,
      isHovering: false,
      sinBinStyles: sinBin
    }))
  }

  render() {
    const { isOver } = this.props
    const { countdown, modal, sinBinStyles } = this.state

    return (
      <Modal isOpen={modal} toggle={this.toggle} onClosed={() => isOver(false)} backdrop={'static'}>
        <ModalHeader toggle={this.toggle}>Break Confirmation</ModalHeader>
        <ModalBody>
        <p>Acknowledge each break by placing your mouse pointer in a box for 10 seconds, 
          only then we know you have truly stopped what you were doing!</p>
          <div style={sinBinStyles}
            onMouseEnter={() => this.onMouseEnterHandler()}
            onMouseLeave={() => this.onMouseLeaveHandler()}
          >
            {(countdown === 0) && (
              <>🎉</>
            )}
            {(countdown !== 0) && (
              <>{countdown}</>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => isOver(false)}>Skip</Button>
        </ModalFooter>
      </Modal>
    );
  }
}


const sinBin = {
    margin: '10px',
    border: '20px dashed gray',
    height: '300px',
    fontSize: '70px',
    color: 'gray',
    textAlign: 'center',
    paddingTop: '90px',
    cursor: 'pointer'
}

const sinBinAmber = {
  cursor: 'wait',
  border: '20px dashed orange',
  color: 'orange'
}

export default BreakModal
