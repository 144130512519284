import React, { Component } from 'react'
import {
  Card,
  CardText,
  CardHeader,
  CardBody,
  Progress,
  Button,
  Row,
  Col
} from 'reactstrap'
import { FaCog, FaTimes } from 'react-icons/fa'
import BreakModal from '../BreakModal'
import EditModal from '../EditModal'

class Reminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminder: {},
      subtitle: '',
      percent: 100,
      remaining: {
        days: 0,
        hours: 0,
        min: 0,
        sec: 0
      },
      breakModalOpen: false,
      editModalOpen: false
    }
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      this.handleTick()
      //if (!date) this.stop()
    }, 1000)
  }

  handleTick() {
    // implement this
  }

  reset() {
    // implement this
  }

  getTimeRemaining(remaining) {
    const timeLeft = {
      hours: 0,
      min: 0,
      sec: 0
    }
    if (remaining >= 3600) {
      timeLeft.hours = Math.floor(remaining / 3600)
      remaining -= timeLeft.hours * 3600
    }
    if (remaining >= 60) {
      timeLeft.min = Math.floor(remaining / 60)
      remaining -= timeLeft.min * 60
    }
    timeLeft.sec = Math.floor(remaining)
    return timeLeft
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  takeBreak() {
    this.showNotification()
    this.reset()
    this.setState(prevState => ({
      ...prevState,
      breakModalOpen: true
    }))
  }

  showNotification() {
   const { reminder } = this.props

   if (("Notification" in window)) {
      if (Notification.permission === "granted") {
        var options = {
             body: reminder.name + ' [click to confirm break]'
         };
         var n = new Notification("It's time to take a break!", options);
         n.addEventListener("click", function (event) {
           window.focus();
           this.close();
        } )
      }
    }
 }

 notificationClick(event) {
   window.focus()
   this.n.close(event.target.tag)
 }

 handleBreakOver(confirmed) {
   const { handleConfirmed, reminder } = this.props
   if (confirmed) {
     handleConfirmed(reminder.id)
   }
   this.setState(prevState => ({
     ...prevState,
     breakModalOpen: false
   }))
 }
 handleCloseEdit() {
  this.setState(prevState => ({
    ...prevState,
    editModalOpen: false
  }))
 }

  handleSaveReminder(r) {
    const { handleSaved } = this.props
    handleSaved(r)
    //this.reset()
    this.handleCloseEdit()
  }

 handleEditClick() {
   this.setState(prevState => ({
     ...prevState,
     editModalOpen: true
   }))
 }

  handleRemoveClick(r) {
    const { handleRemove, reminder } = this.props
    handleRemove(reminder)
  }

  render() {
    const {
      remaining,
      type,
      subtitle,
      percent,
      editModalOpen,
      breakModalOpen
    } = this.state
    const {
      reminder
    } = this.props

    return (
        <>
        <Card style={{marginBottom: '20px'}}>
            <CardHeader>
              <Progress animated
                color={((type === 'interval') ? "success" : "info")}
                value={percent}
                style={{ height: '40px',
                        fontSize : '1rem',
                        backgroundColor: '#303030'
                      }}
                onClick={() => this.takeBreak()}
              >
              {remaining.hours > 0 && (
                <>{remaining.hours} hours{' '}</>
              )}
              {remaining.min > 0 && (
                <>{remaining.min} minutes{' '}</>
              )}
              {(remaining.sec > 1 && remaining.hours === 0) && (
                <>{remaining.sec} seconds</>
              )} till next break
              </Progress>
            </CardHeader>
              <CardBody>
              <Row>
                <Col>
                  <h2>{reminder.name}</h2>
                  <CardText>{subtitle}</CardText>
                </Col>
                <Col>
                  <h2>{reminder.total}</h2>
                  <p>Confirmed breaks</p>
                </Col>
                <Col className={'text-right'}>
                  <Button color="secondary" onClick={() => this.handleEditClick()}><FaCog /></Button>
                  {' '}<Button color="secondary" onClick={() => this.handleRemoveClick()}><FaTimes /></Button>
                </Col>
              </Row>
          </CardBody>
        </Card>
         {breakModalOpen && (
           <BreakModal
            isOpen={true}
            isOver={this.handleBreakOver.bind(this)}
           />
         )}
         {editModalOpen && (
           <EditModal
             isOpen={true}
             action={'Edit'}
             reminder={reminder}
             onSave={this.handleSaveReminder.bind(this)}
             earlyExit={this.handleCloseEdit.bind(this)}
           />
         )}
      </>
    );
  }
}

export default Reminder
