import React from 'react'
import { connect } from 'react-redux'
import IntervalReminder from './Reminders/Interval'
import DailyReminder from './Reminders/Daily'
import { getNewReminder } from '../utils/data'
import { FaPlus } from 'react-icons/fa'
import NumberFormat from 'react-number-format'
import EditModal from './EditModal'
import {
  confirmBreak,
  saveReminder,
  removeReminder,
  addReminder
} from '../actions/reminders'

import { bumpCount, getStats } from '../utils/data'
import { receiveGlobalCount } from '../actions/general'

import { Container, Alert, Button, Badge, UncontrolledTooltip } from 'reactstrap'

class App extends React.Component {

  constructor(props) {
    super(props)

    const { dispatch } = this.props

    this.handleSaveReminder = this.handleSaveReminder.bind(this)
    //this.handleAddReminder = this.handleAddReminder.bind(this)
    this.handleBreakConfirmed = this.handleBreakConfirmed.bind(this)
    this.handleRemoveReminder = this.handleRemoveReminder.bind(this)


    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }

    this.state = {
      hasNotificationPermission: (Notification.permission === "granted"),
      addModalOpen: false
    }
    // on app load, get stats
    getStats().then(function(stats) {
      dispatch(receiveGlobalCount(stats.total_confirmed))
    })


  }

  handleNotificationPermissionClick() {
    var that = this
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        that.setState({hasNotificationPermission: true})
      }
    })
  }

  handleSaveReminder(r) {
     const { dispatch } = this.props
     dispatch(saveReminder(r))
  }

  handleAddReminder(r) {
     const { dispatch } = this.props
     dispatch(addReminder(r))
     // close the modal
     this.setState(prevState => ({
       ...prevState,
       addModalOpen: false
     }))
  }

  handleBreakConfirmed(id) {
    const { dispatch } = this.props
    dispatch(confirmBreak(id))

    // on confirmed, bump the global total
    bumpCount().then(function(stats) {
      dispatch(receiveGlobalCount(stats.total_confirmed))
    });

  }

  handleRemoveReminder(id) {
    const { dispatch, reminders } = this.props

    if (Object.keys(reminders).length > 1) {
      dispatch(removeReminder(id))
    } else {
      alert('you need at least one break!')
    }
  }

  handleAddClick() {
    this.setState(prevState => ({
      ...prevState,
      addModalOpen: true
    }))
  }

  render()  {
    const { hasNotificationPermission, addModalOpen } =  this.state
    const { reminders, general } = this.props
    const newReminder = getNewReminder()
    return (
      <Container style={{maxWidth: '850px', paddingTop: '20px'}}>
         <h2 className={'text-center'}>regular <strong>breaks</strong>
          {' '}<Badge color="success" pill id="UncontrolledTooltipExample">
        {<NumberFormat value={general.globalBreaks} displayType={'text'} thousandSeparator={true} />}
      </Badge>
      <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample">
        Total Global Breaks!
      </UncontrolledTooltip>
        </h2>
        <Alert color={'light'}>
          This is a free tool that reminds you to take regular breaks,
          it requires no login or download, the countdown has started
          below! Simply keep this page open in your browser while you work.
        </Alert>
        {!hasNotificationPermission && (
          <Alert color="info">
             This tool needs permission to display notifications so you know when to break!
             {'  '}<Button
              onClick={() => this.handleNotificationPermissionClick()}
              className="alert-link text-white"
              color="info">
                enable them
              </Button>
          </Alert>
        )}
        {reminders.map((reminder) => (
          <div key={reminder.id}>
          {reminder.type === 'interval' && (
              <IntervalReminder
                reminder={reminder}
                handleConfirmed={this.handleBreakConfirmed}
                handleSaved={this.handleSaveReminder}
                handleRemove={this.handleRemoveReminder}
              ></IntervalReminder>
          )}
          {reminder.type === 'daily' && (
              <DailyReminder
                reminder={reminder}
                handleConfirmed={this.handleBreakConfirmed}
                handleSaved={this.handleSaveReminder}
                handleRemove={this.handleRemoveReminder}
              ></DailyReminder>
          )}
          </div>
        ))}
        <div className={'text-center'}>
          <Button color="secondary" onClick={() => this.handleAddClick()}><FaPlus /> Add Reminder</Button>
        </div>
        {addModalOpen && (
          <EditModal
            isOpen={true}
            action={'Add'}
            reminder={newReminder}
            onSave={this.handleAddReminder.bind(this)}
            earlyExit={() => {}}
          />
        )}
        <Alert color={'light'} className={'text-center'}>
        Privacy: We don't track you general usage although we do like to count the confirmed breaks, <br />to add to a global total, this is done anonymously.
        <br /> By <a href={'http://monkeymatt.com'} target={'_blank'}>Matt Lester</a>
        </Alert>
      </Container>
  )}
}

function mapStateToProps ({ reminders, general }) {
  return {
    reminders,
    general
  }
}

export default connect(mapStateToProps)(App)
