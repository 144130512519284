import {
  RECEIVE_GLOBAL_TOTAL
} from '../actions/general'

var defaultState = {
  globalBreaks : 0,
  localBreaks: 0
}

export default function general (state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_GLOBAL_TOTAL :
    return {
      ...state,
       globalBreaks: action.payload
    }
    default :
      return state
  }
}
