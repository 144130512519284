import uuid from "uuid"

export function incrementGlobalBreaks() {
  return new Promise()
}

export function getNewReminder() {
  return {
      id: uuid.v4(),
      type: "interval",
      minutes: "50",
      hours: "13",
      name: "Coffee Break",
      total: 0
  }
}

export function getStats() {
  return fetch('/.netlify/functions/stats-read').then((response) => {
    return response.json()
  })
}

export function bumpCount() {
  return fetch('/.netlify/functions/count-bump').then((response) => {
    return response.json()
  })
}
