import Reminder from './Reminder'

/*
  Get next target date/time for break
*/
function getNextDate(reminder) {
  let endDate = new Date()
  let todayNow = new Date()
  const { hours, minutes } = reminder
  endDate.setHours(hours)
  endDate.setMinutes(minutes)
  // check if the time has already passed
  // today, if so, add a day, lets target tomorrow.
  if (endDate.getTime() <= todayNow.getTime()) {
    endDate.setDate(endDate.getDate() + 1)
  }
  return endDate
}

class Daily extends Reminder {

  static getDerivedStateFromProps(props, state) {
    const { hours, minutes } = props.reminder
    const time = hours + ':' + minutes
    if (props.reminder !== state.reminder) {
      return {
        reminder: props.reminder,
        type: 'daily',
        subtitle: 'A daily break at ' + time + ' every day',
        endDate: getNextDate(props.reminder)
      };
    }
    return null
  }

  handleTick() {
    const endDate = this.state.endDate
    let diff = Math.floor((endDate - Date.parse(new Date())) / 1000)
    if (diff === 0) {
      this.takeBreak()
      return
    }
    // i've just opened my laptop and its passed
    // the time so don't run the reminder, just reset
    if (diff < 0) this.reset()
    const timeLeft = this.getTimeRemaining(diff)
    this.setState(prevState => ({
      ...prevState,
      remaining: timeLeft,
      percent: 100 - Math.floor((diff / 86400 * 100))
    }))
  }

  reset() {
    this.setState(prevState => ({
      ...prevState,
       'endDate' : getNextDate(this.props.reminder)
    }))
  }
}

export default Daily
